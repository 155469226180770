<template>
    <div class="category-root">
        <h2>批量下单</h2>
        <div class="newCategory" v-loading="loading">
            <bread-nav></bread-nav>
            <el-upload :auto-upload="false" action="" :show-file-list="false" :on-change="onChange" :limit="1" style="float: left; margin-right: 30px">
                <el-button class="export"> <img src="~img/alibaba/import.png" />excel文件导入 </el-button>
            </el-upload>
            <el-button class="download" @click="exportTable()"> <img src="~img/alibaba/import.png" />excel文件下载 </el-button>
            <div class="goods-list">
                <el-table ref="dataTable" :data="goodsList" :header-cell-style="{ background: 'rgb(250,250,250)', color: '#333333' }" @selection-change="setCheck" style="width: 80%">
                    <el-table-column type="selection" width="50"> </el-table-column>
                    <el-table-column prop="sku_image" label="商品图片" width="80" align="center">
                        <template slot-scope="scope">
                          <a :href="'/sku-'+scope.row.goods_id">
                            <img :src="$img(scope.row.goods_image)" min-width="70" height="70" />
                          </a>
                        </template>
                    </el-table-column>
                    <el-table-column prop="goods_name" label="商品名称" align="center">
                      <template slot-scope="scope">
                      <a :href="'/sku-'+scope.row.goods_id">
                        {{ scope.row.goods_name }}
                      </a>
                      </template>
                    </el-table-column>
                    <el-table-column prop="sku_no" label="国际条码" align="center"> </el-table-column>
                    <el-table-column prop="goods_no" label="商品货号" align="center" width="80"> </el-table-column>
                    <!-- 	<el-table-column
					    prop="goods_name"
					    label="商品颜色" align="center">
					</el-table-column> -->
                    <el-table-column prop="brand_name" label="品牌" align="center" width="60"> </el-table-column>
                    <!-- 	<el-table-column
					    prop="goods_name"
					    label="整箱数量" align="center">
					</el-table-column> -->
                    <el-table-column prop="goods_stock" label="库存数量" width="80" align="center"> </el-table-column>
                    <el-table-column prop="price" label="您的价格" width="80" align="center">
                        <template slot-scope="scope">
                            {{ logined ? scope.row.price : "仅会员可见" }}
                        </template>
                    </el-table-column>
                    <el-table-column label="订单数量" width="200" align="center">
                        <template slot-scope="scope">
                            <div class="cart-flex">
                                <el-input-number size="mini" v-model="scope.row.buy_num" @change="scope.row.num=scope.row.buy_num" :min="0"></el-input-number>
                                <span class="join-cart" @click="joinCart(scope.row)"><img src="~img/alibaba/cart-fff.png" /></span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="商品小计" width="80" align="center">
                        <template slot-scope="scope"> ￥{{ Mul(scope.row.price, (scope.row.buy_num || 0)).toFixed(2) }} </template>
                    </el-table-column>
                    <el-table-column label="操作" width="80" align="center">
                        <template slot-scope="scope">
                            <span class="delete" @click="deleteGoods(scope.$index)">删除</span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="controller">
                <div class="controller-left">
                    <el-button class="control-btn" @click="addCollect" :disabled="!checkedGoods.length">加入收藏夹</el-button>
                    <el-button class="control-btn delete" @click="batchDeleteGoods" :disabled="!checkedGoods.length">删除</el-button>
                    <div class="price">
                        合计：<span>￥{{ totalPrice }}</span
                        >元
                    </div>
                </div>
                <div class="controller-right">
                    <el-button class="submit-btn" @click="actionOrder(0)" :disabled="!checkedGoods.length">提交订单</el-button>
                    <el-button class="submit-btn green" @click="actionOrder(1)" :disabled="!checkedGoods.length">提交预订单</el-button>
                    <el-button class="submit-btn red" @click="batchCart" :disabled="!checkedGoods.length">加入购物车</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BreadNav from "@/layout/components/bread-nav"
import { goodsSkuPage, goodsReserve } from "@/api/goods/goods"
import { mapGetters } from "vuex"
import { addCollect } from "@/api/goods/goods_collect"
export default {
    name: "piliangxiadan",
    components: {
        BreadNav
    },
    data: () => {
        return {
            goodsCategory: [],
            goodsList: [],
            total: 0,
            // totalPrice: "0.00",
            loading: false,
            pageSize: 24,
            page: 1,
            checkedGoods: []
        }
    },
    created() {
        this.category_id = this.$route.query.category_id || 0
        // this.getGoodsSkuPage()
    },
    mounted() {
        window.addEventListener("scroll", this.handleScroll)
    },
    computed: {
        ...mapGetters(["member"]),
        logined: function () {
            return this.member !== undefined && this.member !== "" && this.member !== {}
        },
        totalPrice() {
            if (this.checkedGoods.length) {
                let totalPrice = 0

                this.checkedGoods.forEach((item) => {
                    totalPrice += item.price * item.num
                })
                return totalPrice.toFixed(2)
            } else {
                return "0.00"
            }
        }
    },
    methods: {
        batchCart() {
            this.checkedGoods.forEach((item) => {
                this.joinCart(item)
            })
        },
        // 加入购物车
        joinCart(goods) {
            //纠正数量
            if (goods.stock == 0) {
                this.$message({
                    message: "商品已售罄",
                    type: "warning"
                })
                return
            }
            goods.num = goods.buy_num;
            if (goods.num == 0) {
                this.$message({
                    message: "购买数量不能为0",
                    type: "warning"
                })
                return
            }

            if (goods.num > goods.stock) {
                this.$message({
                    message: "购买数量不能大于库存量",
                    type: "warning"
                })
                return
            }

            this.$store
                .dispatch("cart/add_to_cart", {
                    sku_id: goods.sku_id,
                    num: goods.num
                })
                .then((res) => {
                    var data = res.data
                    if (data > 0) {
                        this.$message({
                            message: "加入购物车成功",
                            type: "success"
                        })
                    }
                })
                .catch((err) => {
                    if (err.message == "您尚未登录，请先进行登录") {
                        this.$router.push("/login")
                    } else {
                        this.$message.error(err.message)
                    }
                })

            return true
        },
        // 提交订单 0 普通 1预订单
        actionOrder(type = 0) {
            // 结算
            let sku_ids = []
            this.checkedGoods.forEach((item) => {
                sku_ids.push({ sku_id: item.sku_id, num: item.num })
            })

            var data = {
                sku_lists: JSON.stringify(sku_ids)
            }
            this.$store.dispatch("order/setOrderCreateData", data)
            this.$router.push({
                path: "/payment?type=" + type
            })
        },
        // 加入收藏
        addCollect() {
            this.checkedGoods.forEach((item) => {
                addCollect({
                    sku_id: item.sku_id,
                    goods_id: item.goods_id
                })
                    .then((res) => {
                        var data = res.data
                        if (data > 0) {
                            this.$message("收藏成功")
                        }
                    })
                    .catch((err) => {
                        if (err.message == "您尚未登录，请先进行登录") {
                            this.$router.push("/login")
                        }
                    })
            })
        },
        // 删除商品
        deleteGoods(index) {
            this.goodsList.splice(index, 1)
        },
        // 删除商品
        batchDeleteGoods() {
            this.goodsList.forEach((item, index) => {
                for (let i in this.checkedGoods) {
                    if (this.checkedGoods[i].sku_id == item.sku_id) {
                        this.goodsList.splice(index, 1)
                        break
                    }
                }
            })

            this.checkedGoods = []
        },
        // 引入商品
        async onChange(file) {
            let xlsx = require("xlsx")
            let dataBinary = await this.$util.readFile(file.raw)
            let workBook = xlsx.read(dataBinary, { type: "binary", cellDates: true })
            let workSheet = workBook.Sheets[workBook.SheetNames[0]]
            const data = xlsx.utils.sheet_to_json(workSheet)

            let skuList = []
            if (!data.length) return this.$message("导入结果为空")
            data.forEach((item) => {
                skuList.push({ sku_id: item["国际条码"], num: item["数量"] })
            })
            console.log(skuList)
            this.loading = true
            goodsReserve({ sku_list: skuList })
                .then((res) => {
                    if (res.code != 0) throw new Error(res.message || "导入失败")
                    let list = []
                    res.data.forEach((item) => {
                        item.num = item.buy_num
                        list.push(item)
                    })
                    this.goodsList = list
                    this.total == list.length
                    this.loading = false
                    this.$refs.dataTable.toggleAllSelection();
                })
                .catch((e) => {
                    this.loading = false
                  this.$message(e.message || "导入失败")
                })
        },
        // 下载文件
        exportTable() {
            const _this = this
            let xlsx = require("xlsx")
            let tableData = [
                ["国际条码", "数量"] //导出表头
            ] // 表格表头
            this.goodsList.forEach((item, index) => {
                let rowData = []
                //导出内容的字段
                rowData = [item.sku_no, item.buy_num]
                tableData.push(rowData)
            })
            let ws = xlsx.utils.aoa_to_sheet(tableData)
            let wb = xlsx.utils.book_new()
            xlsx.utils.book_append_sheet(wb, ws, "批量下单") // 工作簿名称
            xlsx.writeFile(wb, "批量下单.xlsx") // 保存的文件名
        },
        setCheck(e) {
            this.checkedGoods = e
        },
        // 计算购物车总价
        calculationTotalPrice() {
            if (this.checkedGoods.length) {
                let totalPrice = 0

                this.checkedGoods.forEach((item) => {
                    totalPrice += item.discount_price * item.num
                })
                this.totalPrice = totalPrice.toFixed(2)
            } else {
                this.totalPrice = "0.00"
            }
        },
        //乘法函数，用来得到精确的乘法结果
        //说明：javascript的乘法结果会有误差，在两个浮点数相乘的时候会比较明显。这个函数返回较为精确的乘法结果。
        //调用：$h.Mul(arg1,arg2)
        //返回值：arg1乘以arg2的精确结果
        Mul: function(arg1, arg2) {
          arg1 = parseFloat(arg1);
          arg2 = parseFloat(arg2);
          var m = 0,
              s1 = arg1.toString(),
              s2 = arg2.toString();
          try {
            m += s1.split(".")[1].length
          } catch (e) {}
          try {
            m += s2.split(".")[1].length
          } catch (e) {}
          return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m);
        }
    }
}
</script>
<style lang="scss" scoped>
.category-root {
    width: $width;
    margin: 0 auto;
    h2 {
        font-size: 24px;
        color: $ns-text-color-black;
        padding-bottom: 5px;
        border-bottom: 1px solid #d9d9d9;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .switchTab {
            display: flex;
            align-items: center;

            .tab-item {
                font-size: $ns-font-size-lg;
                font-weight: 400;
                padding: 0 15px;
                border-right: 1px solid #e1e1e1;
                height: 20px;
                display: block;
                line-height: 20px;
                cursor: pointer;

                &:last-child {
                    border: 0;
                }
            }
        }
    }
}
.newCategory {
    padding: 30px 0;
    width: $width;
    margin: 0 auto;
    overflow: hidden;

    .cart-flex {
        display: flex;
        align-items: center;

        .join-cart {
            width: 26px;
            height: 26px;
            background-color: $base-color;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 3px;
            margin-left: 5px;
            cursor: pointer;

            img {
                width: 16px;
                height: 16px;
            }
        }
    }

    .export {
        width: 150px;
        height: 40px;
        margin: 30px 0;
        border-radius: 5px;
        background-color: $base-color;
        color: #ffffff;
        font-size: $ns-font-size-base;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;

        img {
            width: 15px;
            height: 15px;
            margin-right: 5px;
        }
    }
    .download {
        width: 150px;
        height: 40px;
        margin: 30px 0;
        border-radius: 5px;
        background-color: #2d9842;
        color: #ffffff;
        font-size: $ns-font-size-base;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;

        img {
            width: 15px;
            height: 15px;
            margin-right: 5px;
        }
    }

    .controller {
        display: flex;
        margin-top: 30px;
        align-items: center;
        width: 80%;

        .controller-left {
            width: 60%;
            height: 40px;
            border: 1px solid #d9d9d9;
            background-color: $base-color-gray;
            display: flex;
            align-items: center;
            position: relative;
            box-sizing: border-box;
            padding-left: 20px;

            .control-btn {
                height: 32px;
                padding: 0 20px;
                border-radius: 14px;
                font-size: $ns-font-size-base;
                color: #ffffff;
                background-color: $base-color;
                border: none;

                &.delete {
                    background-color: $base-color-info;
                }
            }

            .price {
                position: absolute;
                right: 20px;
                top: 0;
                line-height: 40px;
                font-size: $ns-font-size-base;
                color: $ns-text-color-black;

                span {
                    color: red;
                    font-size: 24px;
                    font-weight: bold;
                    margin: 0 3px;
                }
            }
        }

        .controller-right {
            width: 35%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .el-button {
                padding: 0;
                width: 130px;
                height: 40px;
                border: 0;
                display: flex;
                align-items: center;
                color: #ffffff;
                justify-content: center;
                font-size: $ns-font-size-lg;
                background-color: $base-color;
                border-radius: 0;

                &.is-disabled {
                    opacity: 0.7;
                }

                &.green {
                    background-color: #2d9842;
                }
            }
        }
    }

    .delete {
        font-size: $ns-font-size-base;
        color: $base-color;
        cursor: pointer;
    }
}
</style>
